
import { FOOTER } from '../../assets/utils/constants';
import './Footer.css';

function Footer() {
    return (
        <footer className='footer'>
            <div className='footer__box'>
                {FOOTER.links.map((column, column_i) => (
                    <div className='footer__column' key={`footer__column_${column_i}`}>
                        <p className='footer__column-title bold'>{column.title}</p>
                        <div className='footer__column-items'>
                            {column.items.map((item, item_i) => {
                                if (item.type === 'link') {
                                    return (
                                        <a className='footer__column-item footer__column-item_type_link' href={item.to} target='_blank' rel='noreferrer'>{item.label}</a>
                                    )
                                } else {
                                    return (
                                        <p className='footer__column-item'>{item.label}</p>
                                    )
                                }
                            })}

                        </div>
                    </div>
                ))}
                <p>Ⓒ Все права защищены. Polza – {new Date().getFullYear()}</p>
            </div>

        </footer>
    );
}

export default Footer