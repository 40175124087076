import moment from 'moment-timezone';
import { ShareIcon } from '../../../../assets/icon/createmarket';
import './Intro.css';
import 'moment/min/locales';
moment.locale('ru');

function Intro({ title, date, onShareClick }) {
  return (
    <div className='intro'>
      <div>
        {date ? (
          <p className='intro__data'>
            {moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format('D MMMM YYYY')}
          </p>
        ) : null}
        {title ? (
          <h1
            className='intro__main-title'
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
        ) : null}
      </div>
      <button className='button-share' type='buton' onClick={onShareClick}>
        <ShareIcon strokeClassName='share-icon' mainClassName='share-icon' />
      </button>
    </div>
  );
}

export default Intro;
