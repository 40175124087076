import { useEffect, useRef, useState } from 'react';
import './SideMenu.css';
import { CloseIcon, MenuMobile } from '../../../assets/icon/createmarket';
import { motion } from 'framer-motion';
import ChooseButtonBlock from './ChooseButtonBlock/ChooseButtonBlock';
import ContentBlock from './ContentBlock/ContentBlock';
import SearchBlock from './SearchBlock/SearchBlock';
import BookmarksBlock from './BookmarksBlock/BookmarksBlock';
import useEscapeKey from '../../../assets/hooks/useEscapeKey';

function SideMenu({ content, articleObj }) {
  function handleMenu(ids) {
    if (form.isOpenMenu && ids === type) {
      setForm((prevValue) => ({
        ...prevValue,
        isOpenMenu: false,
        closeIcon: false,
      }));
      setType(0);
    } else {
      setForm((prevValue) => ({
        ...prevValue,
        isOpenMenu: !form.isOpenMenu,
        closeIcon: true,
      }));
      setType(ids);
    }
  }

  function currentTitle() {
    setForm((prevValue) => ({
      ...prevValue,
      isOpenMenu: false,
      closeIcon: false,
    }));
    setType(0);
    setOpenMobile(false);
  }

  const [type, setType] = useState(0);
  const [form, setForm] = useState({
    isOpenMenu: false,
    closeIcon: false,
  });

  let num = 1;

  const [openMobile, setOpenMobile] = useState(false);

  function handleMenuMobile() {
    setType(0);
    setForm((prevValue) => ({
      ...prevValue,
      isOpenMenu: false,
    }));
    setOpenMobile(!openMobile);
  }

  const overlay = useRef();

  useEscapeKey(currentTitle);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        currentTitle();
      }
    }

    if (
      form.isOpenMenu === false &&
      type === 0 &&
      openMobile === false &&
      type === 0
    ) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
    document.addEventListener('mousedown', handleOverlayClose);
    return () => {
      document.removeEventListener('mousedown', handleOverlayClose);
    };
  });

  const variantsMobile = {
    open: {
      opacity: 1,
      y: '-100%',
      x: '0',
      transition: {
        type: 'spring',
        stiffness: 30,
        restDelta: 2,
      },
    },
    closed: {
      opacity: 0,
      y: '30%',
      x: '0',
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <>
      {openMobile ? (
        <motion.div
          animate={openMobile ? 'open' : 'closed'}
          variants={variantsMobile}
          className={`menu-side_mobile`}
        >
          <button
            className='button button-close'
            onClick={() => handleMenuMobile()}
          >
            <CloseIcon />
          </button>
          <div
            className={`menu-side__button-block_mobile 
              ${
                form.closeIcon && type !== 0
                  ? 'menu-side__button-block_visible'
                  : ''
              }`}
          >
            <ChooseButtonBlock
              type={type}
              form={form}
              handleMenu={handleMenu}
            />
          </div>

          {type === 2 ? (
            <ContentBlock
              articleObj={articleObj}
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              num={num}
              content={content}
              openMobile={openMobile}
              currentTitle={currentTitle}
            />
          ) : type === 3 ? (
            <BookmarksBlock
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              openMobile={openMobile}
            />
          ) : type === 4 ? (
            <SearchBlock
              articleObj={articleObj}
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              openMobile={openMobile}
              currentTitle={currentTitle}
            />
          ) : (
            ''
          )}
        </motion.div>
      ) : (
        <motion.div className={`menu-side`}>
          <div className='menu-side__button-block'>
            <ChooseButtonBlock
              type={type}
              form={form}
              handleMenu={handleMenu}
            />
          </div>
          {type === 2 ? (
            <ContentBlock
              articleObj={articleObj}
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              num={num}
              content={content}
              openMobile={openMobile}
              currentTitle={currentTitle}
            />
          ) : type === 3 ? (
            <BookmarksBlock
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              openMobile={openMobile}
            />
          ) : type === 4 ? (
            <SearchBlock
              articleObj={articleObj}
              type={type}
              form={form}
              handleMenuMobile={handleMenuMobile}
              openMobile={openMobile}
              currentTitle={currentTitle}
            />
          ) : (
            ''
          )}
        </motion.div>
      )}
      <div
        ref={overlay}
        onTouchStart={() => currentTitle()}
        className={`menu-side__bg 
            ${
              (form.closeIcon && type !== 1 && type !== 0) ||
              form.isOpenMenu ||
              openMobile
                ? 'menu-side__bg_open'
                : 'menu-side__bg_close'
            }`}
      ></div>

      <button className='button-menu' onClick={() => handleMenuMobile()}>
        <MenuMobile />
      </button>
    </>
  );
}

export default SideMenu;
