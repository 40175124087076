import { ORDERED_LIST_FORMAT } from '../../../../assets/utils/constants';
import './ListText.css';

function ListText({ data, id }) {
  const { format, children } = data;
  const title = children.find((item) => item.children[0].strikethrough)
    ?.children[0].text;
  const list = children.filter((item) => !item.children[0].strikethrough);

  return (
    <div className='list-text'>
      {title ? (
        <p
          className='list-text__title'
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          id={`${id}0`}
        />
      ) : null}

      {format === ORDERED_LIST_FORMAT ? (
        <ol className='list-text__list'>
          {list.map((item, i) => (
            <li className='list-text__item' key={`${id}${i}`}>
              <p
                className='list-text__item-text'
                dangerouslySetInnerHTML={{
                  __html: item.children[0].text,
                }}
                id={`${id}${list.length === children.length ? i : i + 1}`}
              />
            </li>
          ))}
        </ol>
      ) : (
        <ul className='list-text__list'>
          {list.map((item, i) => (
            <li className='list-text__item' key={`${id}${i}`}>
              <p
                className='list-text__item-text'
                dangerouslySetInnerHTML={{
                  __html: item.children[0].text,
                }}
                id={`${id}${list.length === children.length ? i : i + 1}`}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ListText;
