import { useEffect, useState } from 'react';
import close from '../../../../assets/img/close.svg';
import Fuse from 'fuse.js';
import './SearchBlock.css';
import TemplateBlockMenu from '../TemplateBlockMenu/TemplateBlockMenu';
import {
  HEADING_BLOCK,
  LIST_BLOCK,
  PARAGRAPH_BLOCK,
} from '../../../../assets/utils/constants';

function SearchBlock({
  articleObj,
  form,
  type,
  handleMenuMobile,
  currentTitle,
  openMobile,
}) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });
  });

  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setInputValue(value);
    const results = fuse.search(value);
    const items = results.map((result) => result.item);
    setSearchResults(items);
  };

  const cleanSearch = () => {
    setSearchResults([]);
    setInputValue('');
  };

  const fuseOptions = {
    includeScore: true,
    includeMatches: true,
    threshold: 0.2,
    keys: ['title', 'text', 'li'],
  };

  const article = articleObj?.blocks?.reduce((prevVal, item) => {
    const arr = item.main?.reduce((prevArr, block, i) => {
      const newItem = [];
      if (block.type === HEADING_BLOCK)
        newItem.push({
          title: block.children[0].text,
          id: `${block.type}-${item.id}${block.level}${i}`,
        });

      if (block.type === PARAGRAPH_BLOCK)
        newItem.push({
          text: block.children[0].text,
          id: `${block.type}-${item.id}${i}`,
        });

      if (block.type === LIST_BLOCK)
        block.children?.forEach((li, liIndex) =>
          newItem.push({
            text: li.children[0].text,
            id: `${block.type}-${item.id}${i}${liIndex}`,
          })
        );

      return [...prevArr, ...newItem];
    }, []);

    return [...prevVal, ...arr];
  }, []);

  const fuse = new Fuse(article, fuseOptions);

  return (
    <TemplateBlockMenu
      form={form}
      handleMenuMobile={handleMenuMobile}
      type={type}
      name={'Поиск'}
      openMobile={openMobile}
    >
      <div className='search__input-box'>
        <input
          className='search__input'
          onChange={handleSearch}
          value={inputValue}
        />
        <div onClick={cleanSearch}>
          <img src={close} alt='' className='search__clean-input' />
        </div>
      </div>
      {searchResults.length > 0 ? (
        <div className='search__result-text'>
          {searchResults.map((item, i) => (
            <a
              className='search__result-text__current-title'
              href={`#${item.id}`}
              onClick={currentTitle}
            >
              {item.title ? (
                <p
                  className='search__title'
                  dangerouslySetInnerHTML={{
                    __html: item.title,
                  }}
                />
              ) : null}
              {item.text ? (
                <p
                  className='search__text'
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              ) : null}
            </a>
          ))}
        </div>
      ) : null}
    </TemplateBlockMenu>
  );
}

export default SearchBlock;
