import './Article.css';
import logo from '../../assets/img/logo.svg';
import SideMenu from './SideMenu/SideMenu';
import ImageBlock from './Elements/ImageBlock/ImageBlock';
import Intro from './Elements/Intro/Intro';
import TextH1 from './Elements/TextH1/TextH1';
import TextH2 from './Elements/TextH2/TextH2';
import TextP from './Elements/TextP/TextP';
import ListText from './Elements/ListText/ListText';
import { ShareIconMobile } from '../../assets/icon/createmarket';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import trigger from '../../assets/img/trigger.svg';
import mainApi from '../../assets/api/MainApi';
import {
  HEADING_BLOCK,
  IMAGE_BLOCK,
  LIST_BLOCK,
  PARAGRAPH_BLOCK,
} from '../../assets/utils/constants';
import PreloaderBox from '../PreloaderBox/PreloaderBox';
import { Helmet } from 'react-helmet';

function Article() {
  const { translit_name } = useParams();
  const [articleObj, setArticleObj] = useState(undefined);
  const [isPreloaderVisible, setIsPrelouderVisible] = useState(true);
  const [isCopied, setCopied] = useState(false);
  const [content, setContent] = useState({
    isActive: false,
    activeId: '',
  });

  useEffect(() => {
    if (!translit_name) return;

    setIsPrelouderVisible(true);
    mainApi
      .getArticle({ translit_name: translit_name })
      .then((res) => {
        setArticleObj(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPrelouderVisible(false);
      });
  }, [translit_name]);

  function handleShare() {
    if (isCopied) return;
    let text = window.location.href;
    if (navigator.share === undefined) {
      var textArea = document.createElement('textarea');
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'red';
      textArea.value = window.location.href;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'успешно' : 'безуспешно :( ';
        window.alert('Копирование ссылки прошло ' + msg);
        console.log('Копирование ссылки прошло ' + msg);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 750);
      } catch (err) {
        console.log('Oops, unable to copy');
      }
      // document.body.removeChild(window.location.href);
      document.body.removeChild(textArea);
    } else {
      navigator
        .share({ url: text })
        .then()
        .catch((err) => console.log(err));
    }
  }

  return (
    <div className='article'>
      {!isPreloaderVisible ? (
        <SideMenu content={content} articleObj={articleObj} />
      ) : null}
      <div className='blur_mobile' />
      <div className='article__box'>
        <Link to='/'>
          <img className='logo' src={logo} alt='' />
        </Link>

        {!isPreloaderVisible ? (
          <div>
            <Helmet>
              <title>{articleObj ? articleObj.og_title ? articleObj.og_title : articleObj.title : `Polza - Статьи`}</title>
            </Helmet>
            {articleObj ? (
              <Intro
                title={articleObj.title}
                date={articleObj.publishedAt}
                onShareClick={handleShare}
              />
            ) : null}

            {articleObj?.blocks?.map((block) => (
              <div className='article__block' key={`block-${block.id}`}>
                {block.leftImage ? (
                  <ImageBlock data={block.leftImage[0]} isSideImg />
                ) : null}

                <div className='article__main-block'>
                  {block.main?.map((item, i) =>
                    item.type === HEADING_BLOCK && item.level === 1 ? (
                      <TextH1
                        data={item}
                        index={i}
                        id={block.id}
                        setContent={setContent}
                        key={`${item.type}-${block.id}${item.level}${i}`}
                      />
                    ) : item.type === HEADING_BLOCK && item.level === 2 ? (
                      <TextH2
                        data={item}
                        index={i}
                        id={block.id}
                        key={`${item.type}-${block.id}${item.level}${i}`}
                      />
                    ) : item.type === PARAGRAPH_BLOCK ? (
                      <TextP
                        data={item}
                        index={i}
                        id={block.id}
                        key={`${item.type}-${block.id}${i}`}
                      />
                    ) : item.type === LIST_BLOCK ? (
                      <ListText
                        data={item}
                        id={`${item.type}-${block.id}${i}`}
                        key={`${item.type}-${block.id}${i}`}
                      />
                    ) : item.type === IMAGE_BLOCK ? (
                      <ImageBlock
                        data={item.image}
                        key={`${block.id}-${item.type}-${i}`}
                      />
                    ) : null
                  )}
                </div>

                {block.rightImage ? (
                  <ImageBlock data={block.rightImage[0]} isSideImg />
                ) : null}
              </div>
            ))}

            <div className='article__trigger'>
              <h3 className='article__trigger-text'>
                Ведите ваш бизнес с пользой
              </h3>
              <a className='article__trigger__button' href='https://polza.tech'>
                <img src={trigger} alt='' />
              </a>
            </div>
          </div>
        ) : (

          <PreloaderBox />
        )}

        {!isPreloaderVisible ? (
          <div className='button-share__box_mobile'>
            <button className='button-share' type='buton' onClick={handleShare}>
              <ShareIconMobile
                strokeClassName='share-icon-mobile'
                mainClassName='share-icon-mobile'
              />
            </button>
            <p className='button-share__title'>
              {articleObj?.title ? articleObj.title : ''}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Article;
