import React from 'react';
import './TextH2.css';

function TextH2({ data, index, id }) {
  const renderChildren = (children) => {
    return children.map((child, idx) => {
      let childElement = null;

      // Обработка обычного текста
      if (child.type === 'text') {
        childElement = (
          <span
            key={idx}
            className={`text-h2 ${child.bold ? 'bold' : ''} ${child.italic ? 'italic' : ''} ${
              child.underline ? 'underline' : ''} ${child.strikethrough ? 'strikethrough' : ''}`}
          >
            {child.text}
          </span>
        );
      }
      // Обработка ссылок
      else if (child.type === 'link' && child.children) {
        childElement = (
          <a key={idx} href={child.url}
            className={`text-h2 link ${child.bold ? 'bold' : ''} ${child.italic ? 'italic' : ''} ${
              child.underline ? 'underline' : ''} ${child.strikethrough ? 'strikethrough' : ''}`}
          >
            {renderChildren(child.children)}
          </a>
        );
      }
      return childElement;
    });
  };

  return (
    <h3 className={`text-h2`} id={`${data.type}-${id}${data.level}${index}`}>
      {renderChildren(data.children)}
    </h3>
  );
}

export default TextH2;
