import { Route, Routes } from 'react-router';
import Main from '../Main/Main';
import './App.css';
import Article from '../Article/Article';
import Footer from '../Footer/Footer';

function App() {
  return (
    <div className="app">
      <Routes>
        <Route path="/" index element={<Main />} />
        <Route path='/post/:translit_name' element={<Article />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
