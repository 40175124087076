export function ShareIcon({ mainClassName, strokeClassName }) {
  return (
    <svg
      width={28}
      height={28}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
    >
      <path
        d="M17.857 10.333h2.572c1.42 0 2.571 1.094 2.571 2.444v9.778C23 23.905 21.85 25 20.43 25H7.572C6.152 25 5 23.905 5 22.555v-9.777c0-1.35 1.152-2.445 2.572-2.445h2.571M14 17.667V3M17.857 6.667L14 3l-3.857 3.667"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={strokeClassName}
      />
    </svg>
  );
}
export function ShareIconMobile({ mainClassName, strokeClassName }) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
    >
      <path
        d="M10.204 5.904h1.47c.811 0 1.469.626 1.469 1.397v5.588c0 .77-.658 1.396-1.47 1.396H4.328c-.812 0-1.47-.625-1.47-1.396V7.3c0-.771.658-1.397 1.47-1.397h1.47M8 10.095V1.714M10.204 3.81L8 1.713 5.796 3.809"
        stroke="#fff"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={strokeClassName}
      />
    </svg>
  );
}
export function HomeLogo({ mainClassName }) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.456 11.065l-8-6.222a4 4 0 00-4.912 0l-8 6.222A3.998 3.998 0 004 14.223V24a4 4 0 004 4h16a4 4 0 004-4v-9.777c0-1.235-.57-2.4-1.544-3.158z"
        stroke="#A3A5C0"
        strokeWidth={2}
        className={mainClassName}
      />
      <path
        d="M12 22.667h8"
        stroke="#A3A5C0"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={mainClassName}
      />
    </svg>
  );
}
export function ContentLogo({ mainClassName }) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.333 4H10.667A6.667 6.667 0 004 10.667v10.666A6.667 6.667 0 0010.667 28h10.666A6.667 6.667 0 0028 21.333V10.667A6.667 6.667 0 0021.333 4zM12 20h-2M22 20h-8M12 16h-2M22 16h-8M10 12h12"
        stroke="#A3A5C0"
        strokeWidth={2}
        className={mainClassName}
      />
    </svg>
  );
}
export function BookmarksLogo({ mainClassName }) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.333 12v14.661c0 1.012 1.076 1.659 1.966 1.175l5.368-2.928 5.368 2.928A1.33 1.33 0 0020 26.661V12a2.666 2.666 0 00-2.667-2.667H8A2.667 2.667 0 005.333 12z"
        stroke="#A3A5C0"
        strokeWidth={2}
        className={mainClassName}
      />
      <path
        d="M12 9.333V6.667A2.666 2.666 0 0114.667 4H24a2.666 2.666 0 012.667 2.667V21.39a1.334 1.334 0 01-1.972 1.17L20 20"
        stroke="#A3A5C0"
        strokeWidth={2}
        className={mainClassName}
      />
    </svg>
  );
}
export function SearchLogo({ mainClassName }) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.745 24.157a9.412 9.412 0 100-18.824 9.412 9.412 0 000 18.824zM26.667 26.667L21.4 21.4"
        stroke="#A3A5C0"
        strokeWidth={2}
        className={mainClassName}
      />
    </svg>
  );
}
export function ActiveIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={32} height={32} rx={10} fill="#DFE1FF" />
      <path
        d="M21.657 10.343L10.343 21.657M10.343 10.343l11.314 11.314"
        stroke="#5C629B"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}
export function MenuMobile(props) {
  return (
    <svg
      width={26}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0h23l-3 4H0l3-4zM3 16h23l-3 4H0l3-4zM3 8h15l-3 4H0l3-4z"
        fill="#000536"
      />
    </svg>
  );
}

export function CloseIcon(props) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={32} height={32} rx={16} fill="#DFE1FF" />
      <path
        d="M21.657 10.343L10.343 21.657M10.343 10.343l11.314 11.314"
        stroke="#5C629B"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function Arrow({mainClassName}) {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={mainClassName}
    >
      <path
        d="M6 9.4a.6.6 0 000 1.2V9.4zm8.424 1.024a.6.6 0 000-.848l-3.818-3.819a.6.6 0 10-.849.849L13.152 10l-3.395 3.394a.6.6 0 10.849.849l3.818-3.819zM6 10.6h8V9.4H6v1.2z"
        fill="#2D5BFF"
      />
    </svg>
  );
}