import './ContentBlock.css';
import TemplateBlockMenu from '../TemplateBlockMenu/TemplateBlockMenu';
import { HEADING_BLOCK } from '../../../../assets/utils/constants';

function ContentBlock({
  articleObj,
  form,
  type,
  handleMenuMobile,
  currentTitle,
  num,
  content,
  openMobile,
}) {
  return (
    <TemplateBlockMenu
      form={form}
      handleMenuMobile={handleMenuMobile}
      type={type}
      name={'Содержание'}
      openMobile={openMobile}
    >
      <p className='content-title'>
        {articleObj?.title ? articleObj.title : ''}
      </p>
      {articleObj?.blocks?.map((block) =>
        block.main?.map((item, i) =>
          item.type === HEADING_BLOCK && item.level === 1 ? (
            <a
              onClick={currentTitle}
              href={`#${item.type}-${block.id}${item.level}${i}`}
              className={`content-titles__number ${
                content.activeId === item.children[0].text
                  ? 'content-titles__number_active'
                  : ''
              }`}
              key={`${item.type}-${block.id}${item.level}${i}`}
            >
              <p>{num++}. </p>
              <p>{item.children[0].text}</p>
            </a>
          ) : null
        )
      )}
    </TemplateBlockMenu>
  );
}

export default ContentBlock;
