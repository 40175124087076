import { Link } from "react-router-dom";
import { ActiveIcon, ContentLogo, HomeLogo, SearchLogo } from "../../../../assets/icon/createmarket";

function ChooseButtonBlock({ form, type, handleMenu }) {
    return (
        <>
            <Link to={'/'} className='menu-side__button'>
                <HomeLogo mainClassName={'menu-side__button__logo'} />
                <p className='menu-side__button__text'>
                    Главная
                </p>
            </Link>
            <button className='menu-side__button' onClick={() => handleMenu(2)} type="button">
                {form.closeIcon && (type === 2) ?
                    <ActiveIcon />
                    : <ContentLogo mainClassName={'menu-side__button__logo'} />}
                <p className='menu-side__button__text'>
                    Содержание
                </p>
            </button>
            {/* 
                    <div className='menu-side__button' onClick={() => handleMenu(3)}>
                        {form.closeIcon && (type === 3) ?
                            <ActiveIcon />
                            : <BookmarksLogo mainClassName={'menu-side__button__logo'} />}
                        <p className='menu-side__button__text'>
                            Закладки
                        </p>
                    </div>
                    */}
            <button className='menu-side__button' onClick={() => handleMenu(4)} type="button">
                {form.closeIcon && (type === 4) ?
                    <ActiveIcon />
                    : <SearchLogo mainClassName={'menu-side__button__logo'} />}
                <p className='menu-side__button__text'>
                    Поиск
                </p>
            </button>
        </>
    );
}

export default ChooseButtonBlock;