export const MAIN_URL = 'https://articles.polza.tech/api/articles';
export const PHOTO_URL = 'https://articles.polza.tech/uploads';

export const CARDS_LIMIT = 10;

export const HEADING_BLOCK = 'heading';
export const PARAGRAPH_BLOCK = 'paragraph';
export const LIST_BLOCK = 'list';
export const ORDERED_LIST_FORMAT = 'ordered';
export const IMAGE_BLOCK = 'image';

const CONTACT_PHONE = {
    formated_value: '+7 936 100-50-40',
    value: '79361005040'
}

export const FOOTER = {
    links: [
        {
            title: 'Наши ссылки',
            items: [
                {
                    type: 'link',
                    label: 'Сайт',
                    to: 'https://polza.tech',
                },
                {
                    type: 'link',
                    label: 'Личный кабинет',
                    to: 'https://lk.polza.tech',
                },
                {
                    type: 'link',
                    label: 'База знаний',
                    to: 'https://info.polza.tech',
                },
            ],
        },
        {
            title: 'Связаться с нами',
            items: [
                {
                    type: 'link',
                    label: CONTACT_PHONE.formated_value,
                    to: `tel:+${CONTACT_PHONE.value}`,
                },
                {
                    type: 'link',
                    label: 'ВКонтакте',
                    to: 'https://vk.com/polzatech',
                },
            ],
        },
        {
            title: 'Общество с ограниченной ответственностью "СЕЛЛ1" (ООО "СЕЛЛ1")',
            items: [
                {
                    type: 'text',
                    label: 'ИНН:  9714016972',
                },
                {
                    type: 'text',
                    label: 'КПП:  771401001',
                },
                {
                    type: 'text',
                    label: 'ОГРН:  1237700539405',
                },
                {
                    type: 'text',
                    label: 'ОКПО:  55414770',
                },
            ],
        }
    ],

    copyright: `Ⓒ Все права защищены. Polza – ${new Date().getFullYear()}`,
}
