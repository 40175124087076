import { CARDS_LIMIT, PHOTO_URL } from "../../assets/utils/constants";
import "./Main.css";
import logo from "../../assets/img/logo.svg";
import { Link } from "react-router-dom";
import mainApi from "../../assets/api/MainApi";
import { useEffect, useState } from "react";
import PreloaderBox from "../PreloaderBox/PreloaderBox";
import { Arrow } from "../../assets/icon/createmarket";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

function Main() {
  document.body.style.overflow = "initial";

  const [cards, setCards] = useState([]);
  const [isMore, setIsMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isPreloaderVisible, setPreloderVisible] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get("page"));

  async function getArticlesCards({ start }) {
    setPreloderVisible(true);
    return mainApi
      .getAllArticles({
        start: start.toString(),
        limit: (CARDS_LIMIT + 1).toString(),
      })
      .then((res) => {
        setCards(res.slice(0, CARDS_LIMIT));
        setIsMore(res.length > CARDS_LIMIT);
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      })
      .finally(() => {
        setPreloderVisible(false);
      });
  }

  async function getArticles(page) {
    try {
      const res = await getArticlesCards({
        start: page <= 1 ? 0 : (page - 1) * CARDS_LIMIT,
      });
      if (page <= 1) {
        setSearchParams((params) => {
          params.set("page", 1);
          return params;
        });
        setIsEmpty(res.length === 0);
      }

      if (res.length === 0 && page > 1) {
        setSearchParams((params) => {
          params.set("page", 1);
          return params;
        });
        const newRes = await getArticlesCards({ start: 0 });
        setIsEmpty(newRes.length === 0);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("page")) {
      setSearchParams((params) => {
        params.set("page", JSON.parse(localStorage.getItem("page")));
        return params;
      });
      getArticles(page);
    } else {
      const startPage = page > 0 ? page : 1;
      setSearchParams((params) => {
        params.set("page", startPage);
        return params;
      });
      getArticles(startPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("page", JSON.stringify(page));
  }, [page]);

  function MoreArticles() {
    if (!isMore) return;

    setSearchParams((params) => {
      params.set("page", page + 1);
      return params;
    });
    getArticlesCards({ start: page * CARDS_LIMIT });
  }

  function LessArticles() {
    if (page === 1) return;

    setSearchParams((params) => {
      params.set("page", page - 1);
      return params;
    });
    getArticlesCards({ start: (page - 2) * CARDS_LIMIT });
  }

  return (
    <div className="main">
      <Helmet>
        <title>Polza - Статьи</title>
      </Helmet>
      <div className="main__box">
        <img className="main__box_logo" src={logo} alt="" />
        {isPreloaderVisible ? (
          <PreloaderBox />
        ) : (
          <>
            {!isEmpty ? (
              <div className="main__box_grid-choose">
                {cards.map((item) => {
                  return (
                    <Link
                      key={item.id}
                      to={`/post/${item.translit_name}`}
                      className="grid-choose__card"
                    >
                      <img
                        className="grid-choose__card__img"
                        src={`${PHOTO_URL}/${item.mainPhoto?.hash}${item.mainPhoto?.ext}`}
                        alt=""
                      />
                      <div className="grid-choose__card__text">
                        <p className="grid-choose__card__title">{item.title}</p>
                        <p className="grid-choose__card__subtitle">
                          {item.description}
                        </p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            ) : (
              <div className="article__not-found">
                <p className="article__not-found-text">
                  Упс... Похоже, здесь еще нет статей.
                </p>
              </div>
            )}

            {(page === 1 && isMore) || page > 1 ? (
              <div className="article__trigger__button">
                <button
                  className={`article__trigger-btn ${
                    page === 1 ? "article__trigger-btn_disabled" : ""
                  }`}
                  type="button"
                  onClick={LessArticles}
                >
                  <Arrow mainClassName={"arrow_back"} />
                </button>
                {page}
                <button
                  className={`article__trigger-btn ${
                    !isMore ? "article__trigger-btn_disabled" : ""
                  }`}
                  type="button"
                  onClick={MoreArticles}
                >
                  <Arrow mainClassName={"arrow"} />
                </button>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default Main;
