import React from 'react';
import './TextP.css';

function TextP({ data, index, id }) {
  const renderChildren = (children) => {
    return children.map((child, idx) => {
      let childElement = null;

      // Обработка обычного текста
      if (child.type === 'text') {
        childElement = (
          <span
            key={idx}
            className={`text-p ${child.bold ? 'bold text-p_type_bold' : ''} ${child.italic ? 'italic' : ''
              } ${child.underline ? 'underline' : ''} ${child.strikethrough ? 'strikethrough' : ''
              }`}
          >
            {child.text}
          </span>
        );
      }
      // Обработка ссылок
      else if (child.type === 'link' && child.children) {
        childElement = (
          <a key={idx} href={child.url}
            className={`text-p link ${child.bold ? 'bold text-p_type_bold' : ''} ${child.italic ? 'italic' : ''
              } ${child.underline ? 'underline' : ''} ${child.strikethrough ? 'strikethrough' : ''
              }`}
          >
            {renderChildren(child.children)}
          </a>
        );
      }
      return childElement;
    });
  };

  return (
    <p id={`${data.type}-${id}${index}`} className="text-p">
      {renderChildren(data.children)}
    </p>
  );
}

export default TextP;