import { motion } from "framer-motion"
import { CloseIcon, SearchLogo } from "../../../../assets/icon/createmarket";
import { useEffect } from "react";
import './TemplateBlockMenu.css';

function TemplateBlockMenu({ form, type, handleMenuMobile, openMobile, children, name }) {
    const variants = {
        open: {
            opacity: 1, x: '118%', y: '0', transition: {
                type: "spring",
                stiffness: 30,
                restDelta: 2
            }
        },
        closed: {
            opacity: 0, x: '-120%', y: '0', transition: {
                delay: 0.5,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
    }

    const variantsMobile = {
        open: {
            opacity: 1, y: '-85vh', x: '0', transition: {
                type: "spring",
                stiffness: 30,
                restDelta: 2
            }
        },
        closed: {
            opacity: 0, y: '0', x: '0', transition: {
                delay: 0.5,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
    }

    useEffect(() => {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    });
   
    return (
        <>
            {
                openMobile ?
                    <motion.div
                        animate={(type!==0) ? "open" : "closed"}
                        variants={variantsMobile}
                        className='menu-side__info'>
                        <div className={`menu-side__info__box
                                    ${form.isOpenMenu ?
                                'menu-side__info__box_open'
                                : 'menu-side__info__box_close'}`}>
                        </div>
                        <div className='menu-side__title-up_mobile'>
                            <div className='menu-side__button'>
                                <SearchLogo mainClassName={'menu-side__button__logo'} />
                                <p className='menu-side__button__text'>
                                   {name}
                                </p>
                            </div>
                            <button className='button button-close' onClick={() => handleMenuMobile()}>
                                <CloseIcon />
                            </button>
                        </div>
                        {children}
                    </motion.div>
                    :
                    <motion.div
                        animate={(type!==0) ? "open" : "closed"}
                        variants={variants}
                        className='menu-side__info'>
                        <div className={`menu-side__info__box
                                ${form.isOpenMenu ?
                                'menu-side__info__box_open'
                                : 'menu-side__info__box_close'}`}>
                        </div>
                        {children}
                    </motion.div>
            }
        </>
    );
}

export default TemplateBlockMenu;