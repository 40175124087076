import { useEffect } from 'react';
import './TextH1.css';

function TextH1({ data, index, id, setContent }) {
  useEffect(() => {
    const options = {
      rootMargin: '0px',
      // процент пересечения
      threshold: 1,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const title = entry.target;

          setContent((prevValue) => ({
            ...prevValue,
            isActive: true,
            activeId: title.innerHTML,
          }));

          //   console.log(lazyImg);
          // прекращаем наблюдение
          // observer.unobserve(lazyImg)
        }
      });
    }, options);

    const arr = document.querySelectorAll('.text-h1');

    arr.forEach((i) => {
      observer.observe(i);
    });
    return () => {
      arr.forEach((i) => {
        observer.unobserve(i);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <h2
      className={`text-h1 ${data.children[0].italic ? 'italic' : ''} ${
        data.children[0].underline ? 'underline' : ''
      } ${data.children[0].strikethrough ? 'strikethrough' : ''}`}
      dangerouslySetInnerHTML={{
        __html: data.children[0].text,
      }}
      id={`${data.type}-${id}${data.level}${index}`}
    />
  );
}

export default TextH1;
