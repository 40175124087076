import { useEffect, useState } from 'react';
import { PHOTO_URL } from '../../../../assets/utils/constants';
import './ImageBlock.css';

function ImageBlock({ data, isSideImg }) {
  const { alternativeText, ext, hash } = data;
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    const url = `${PHOTO_URL}/${hash}${ext}`;
    loadImage(url);
  }, [ext, hash]);

  const loadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      setIsImgLoaded(true);
    };
    img.onerror = (err) => {
      console.log('img error:', err);
    };
  };

  return isImgLoaded ? (
    <img
      className={`img-element ${isSideImg ? 'img-element_type_side' : ''}`}
      src={`${PHOTO_URL}/${hash}${ext}`}
      alt={alternativeText}
    />
  ) : (
    <div
      className={`img-element ${isSideImg ? 'img-element_type_side' : ''}`}
    />
  );
}

export default ImageBlock;
