import { motion } from "framer-motion"

function BookmarksBlock({ form, type, handleMenuMobile, currentTitle, openMobile }) {
    const variants = {
        open: {
            opacity: 1, x: '118%', y: '0', transition: {
                type: "spring",
                stiffness: 30,
                restDelta: 2
            }
        },
        closed: {
            opacity: 0, x: '-120%', y: '0', transition: {
                delay: 0.5,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
    }

    const variantsMobile = {
        open: {
            opacity: 1, y: '-125%', x: '0', transition: {
                type: "spring",
                stiffness: 30,
                restDelta: 2
            }
        },
        closed: {
            opacity: 0, y: '100px', x: '0', transition: {
                delay: 0.5,
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        },
    }

    return (
        <>
            {openMobile ?
                <motion.div
                    animate={(type === 3) ? "open" : "closed"}
                    variants={variantsMobile}
                    className='menu-side__info'>
                    <div className={`menu-side__info__box
                         ${form.isOpenMenu ? 'menu-side__info__box_open'
                            : 'menu-side__info__box_close'}`}>
                    </div>
                    <p className='bookmarks__name'>Отцифровка базы данных</p>
                    <p className='bookmarks__text'>Повезло, если у вас уже есть онлайн-реестр (к примеру, часто используемый в России 1С) с продукцией магазина. Однако нередко бывают случаи, когда в этих реестрах сплошная путаница. Тогда...</p>
                    <p className='bookmarks__name'>Отцифровка базы данных</p>
                    <p className='bookmarks__text'>Повезло, если у вас уже есть онлайн-реестр (к примеру, часто используемый в России 1С) с продукцией магазина. Однако нередко бывают случаи, когда в этих реестрах сплошная путаница. Тогда...</p>
                </motion.div>
                :
                <motion.div
                    animate={(type === 3) ? "open" : "closed"}
                    variants={variants}
                    className='menu-side__info'>
                    <div className={`menu-side__info__box
                     ${form.isOpenMenu ? 'menu-side__info__box_open'
                            : 'menu-side__info__box_close'}`}>
                    </div>
                    <p className='bookmarks__name'>Отцифровка базы данных</p>
                    <p className='bookmarks__text'>Повезло, если у вас уже есть онлайн-реестр (к примеру, часто используемый в России 1С) с продукцией магазина. Однако нередко бывают случаи, когда в этих реестрах сплошная путаница. Тогда...</p>
                    <p className='bookmarks__name'>Отцифровка базы данных</p>
                    <p className='bookmarks__text'>Повезло, если у вас уже есть онлайн-реестр (к примеру, часто используемый в России 1С) с продукцией магазина. Однако нередко бывают случаи, когда в этих реестрах сплошная путаница. Тогда...</p>
                </motion.div>
            }
        </>
    );
}

export default BookmarksBlock;